import React, { useState } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

import BlogPreview from 'components/BlogPreview';
import LoadingSpinner from 'components/LoadingSpinner';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { breakpoints } from 'theme';
import ListEmptyState from 'components/ListEmptyState';

import NoBlogPosts from 'images/NoBlogPosts.svg';

const Container = styled.div`
  @media (max-width: ${breakpoints.tablet}) {
    width: 100%;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 16px;
  row-gap: 32px;
  margin-bottom: 56px;

  @media (max-width: ${breakpoints.tablet}) {
    display: flex;
    flex-wrap: wrap;
    row-gap: 24px;
    margin-bottom: 40px;
  }
`;

const SectionTitle = styled.h4`
  color: ${(props) => props.theme.brandPalette.dark};
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(20)};
  line-height: ${rem(32)};
  margin-bottom: 32px;
  text-align: left;
`;

const ErrorMessage = styled.p`
  color: ${(props) => props.theme.semanticPalette.error.base};
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(16)};
  margin: 16px 0;
`;

const Button = styled.button`
  background-color: ${({ theme }) => theme.brandPalette.primary};
  border: ${({ theme }) => `1px solid ${theme.brandPalette.primary}`};
  color: ${({ theme }) => theme.neutralPalette.white};

  padding: 12px 24px;
  border-radius: 8px;
  margin-bottom: 172px;

  font-size: ${rem(16)};
  font-weight: ${(props) => props.theme.fontWeights.bold};

  transition: all 150ms ease-in-out;

  &:hover {
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.brandPalette.primary};
    color: ${({ theme }) => theme.brandPalette.primary};
    background-color: transparent;
  }

  @media (max-width: ${breakpoints.tablet}) {
    width: 100%;
    margin-bottom: 64px;
  }
`;

interface BlogsTabProps {
  posts: any;
  isLoading: boolean;
  error: string | null;
}

const BlogsTab = ({ posts, isLoading, error }: BlogsTabProps) => {
  const [numberOfEntriesToShow, setNumberOfEntriesToShow] = useState<number>(3);
  const { t } = useTranslation();

  const loadMoreEntries = () => {
    setNumberOfEntriesToShow((prevState) => prevState + 3);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error !== null) {
    return <ErrorMessage>{error}</ErrorMessage>;
  }

  if (!isLoading && posts.length === 0) {
    return (
      <ListEmptyState
        image={NoBlogPosts}
        title={'activity_blog_empty_title'}
        subtitle={'activity_blog_empty_body'}
      />
    );
  }

  return (
    <Container>
      <SectionTitle>{t('blog.insights.latest')}</SectionTitle>
      <Grid>
        {posts.slice(0, numberOfEntriesToShow).map((item: any) => (
          <BlogPreview
            element={item.fields}
            datePosted={item.fields.date}
            key={item.fields.title}
          />
        ))}
      </Grid>

      {posts.length > numberOfEntriesToShow && (
        <Button onClick={loadMoreEntries}>
          {t('blog.insights.articles.load_more')}
        </Button>
      )}

      {posts.length > 3 && (
        <>
          <SectionTitle>{t('blog.insights.articles.most_read')}</SectionTitle>
          <Grid>
            {posts.slice(0, 3).map((item: any) => (
              <BlogPreview
                element={item.fields}
                datePosted={item.fields.date}
                key={item.fields.title}
              />
            ))}
          </Grid>
        </>
      )}
    </Container>
  );
};
export default BlogsTab;
