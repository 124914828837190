import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

import formatDate from 'utils/formatDate';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { breakpoints } from 'theme';

const arrowPointerSvg = (
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.08378 8.07665L9.3097 8.07665L5.73322 11.6423C5.52913 11.8464 5.41448 12.1232 5.41448 12.4118C5.41448 12.7004 5.52913 12.9772 5.73321 13.1813C5.9373 13.3853 6.21409 13.5 6.5027 13.5C6.79132 13.5 7.06811 13.3853 7.27219 13.1813L12.6911 7.76235C12.7898 7.65928 12.8671 7.53774 12.9187 7.4047C13.0271 7.14084 13.0271 6.84488 12.9187 6.58103C12.8671 6.44799 12.7898 6.32645 12.6911 6.22338L7.27219 0.804459C7.17144 0.702877 7.05157 0.622248 6.9195 0.567226C6.78743 0.512204 6.64578 0.483877 6.5027 0.483877C6.35963 0.483877 6.21797 0.512204 6.0859 0.567226C5.95384 0.622248 5.83397 0.702877 5.73322 0.804459C5.63163 0.905211 5.55101 1.02508 5.49599 1.15715C5.44096 1.28922 5.41263 1.43087 5.41263 1.57394C5.41263 1.71702 5.44096 1.85867 5.49599 1.99074C5.55101 2.12281 5.63163 2.24268 5.73322 2.34343L9.3097 5.90908L1.08378 5.90908C0.796346 5.90908 0.520681 6.02326 0.317432 6.22651C0.114183 6.42976 5.94001e-07 6.70542 5.68872e-07 6.99286C5.43743e-07 7.2803 0.114183 7.55596 0.317432 7.75921C0.520681 7.96246 0.796346 8.07665 1.08378 8.07665Z" />
  </svg>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Card = styled.div`
  height: 359px;
  display: flex;
  border-radius: 8px;
  max-width: 1080px;
  background: #fff;
  box-shadow: rgb(46 41 51 / 8%) 0px 1px 2px, rgb(71 63 79 / 8%) 0px 2px 4px;
  transition: all 150ms ease-in-out;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  @media (max-width: ${breakpoints.tablet}) {
    box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.1);
    flex-direction: column;
    height: auto;
  }
`;

const ThumbnailWrapper = styled.div`
  flex: 1;
`;

const ArticleThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  @media (max-width: ${breakpoints.tablet}) {
    max-height: 216px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0;
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  text-align: left;
  padding: 32px 32px 24px 32px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${breakpoints.tablet}) {
    padding: 8px 16px 30px 16px;
  }

  #externalLink {
    display: flex;
    gap: 13px;
    align-items: center;
    justify-content: flex-end;

    margin-top: 40px;

    color: ${(props) => props.theme.brandPalette.primary};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    font-size: ${rem(16)};
    line-height: ${rem(24)};

    transition: all 150ms ease-in-out;

    @media (max-width: ${breakpoints.tablet}) {
      margin-top: 32px;
    }

    svg {
      fill: ${(props) => props.theme.brandPalette.primary};
      transition: all 150ms ease-in-out;
    }

    &:hover {
      cursor: pointer;
      color: ${(props) => props.theme.brandPalette.secondary};

      svg {
        fill: ${(props) => props.theme.brandPalette.secondary};
      }
    }
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    margin-top: 32px;
  }
`;

const ArticleAuthorLogo = styled.img`
  max-height: 36px;
  width: auto;
`;

const ArticleDate = styled.h4`
  color: ${(props) => props.theme.neutralPalette.neutral6};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${rem(12)};
  line-height: ${rem(18)};
`;

const ArticleTitle = styled.h5`
  color: ${(props) => props.theme.brandPalette.dark};
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(20)};
  line-height: ${rem(32)};

  margin-bottom: 24px;
  margin-top: 32px;

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${rem(18)};
    line-height: ${rem(22)};
    margin-top: 8px;
  }
`;

const ArticleContent = styled.p`
  color: ${(props) => props.theme.brandPalette.dark};
  opacity: 0.8;
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${rem(16)};
  line-height: ${rem(24)};

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;

  @media (max-width: ${breakpoints.tablet}) {
    -webkit-line-clamp: 3;
  }
`;

const ArticleLink = styled.a``;

const ArticleCreditDesktop = styled.p`
  display: block;
  text-align: left;
  margin-top: 8px;

  color: ${(props) => props.theme.brandPalette.dark};
  opacity: 0.8;
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${rem(12)};
  line-height: ${rem(18)};

  @media (max-width: ${breakpoints.tablet}) {
    display: none;
  }
`;

const ArticleCreditMobile = styled.p`
  display: none;

  @media (max-width: ${breakpoints.tablet}) {
    display: block;
    text-align: left;

    color: ${(props) => props.theme.brandPalette.dark};
    opacity: 0.8;
    font-weight: ${(props) => props.theme.fontWeights.regular};
    font-size: ${rem(12)};
    line-height: ${rem(18)};
  }
`;

interface PressPostProps {
  element: {
    title: string;
    imageUrl: string;
    logoUrl: string;
    date: string | Date;
    description: string;
    pressUrl: string;
    imageSource: string;
    imageDescription: string;
  };
}

const PressPostPreview = ({ element }: PressPostProps) => {
  const {
    title,
    imageUrl,
    logoUrl,
    date,
    description,
    pressUrl,
    imageSource,
    imageDescription,
  } = element;

  const { t } = useTranslation();

  const formattedDate = formatDate(date);

  return (
    <Container>
      <Card>
        <ThumbnailWrapper>
          <ArticleThumbnail src={imageUrl} alt={imageDescription ?? ''} />
        </ThumbnailWrapper>
        <ContentWrapper>
          <ArticleCreditMobile>
            {t('press.details.copyright_disclaimer')} {imageSource}
          </ArticleCreditMobile>
          <Flex>
            <ArticleAuthorLogo src={logoUrl} alt="" aria-hidden="true" />
            <ArticleDate>{formattedDate}</ArticleDate>
          </Flex>
          <ArticleTitle>{title}</ArticleTitle>
          <ArticleContent>{description}</ArticleContent>
          <ArticleLink target="_blank" id="externalLink" href={pressUrl}>
            {t('press.details.access')} {arrowPointerSvg}
          </ArticleLink>
        </ContentWrapper>
      </Card>
      <ArticleCreditDesktop>
        {t('press.details.copyright_disclaimer')} {imageSource}
      </ArticleCreditDesktop>
    </Container>
  );
};

export default PressPostPreview;
