import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';

const NavItem = styled.li`
  text-transform: uppercase;

  text-align: center;
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  color: ${(props) => props.theme.brandPalette.primary};

  border-bottom: 2px solid transparent;
  padding: 0 16px 16px 16px;

  transition: all 150ms ease-in-out;

  &:hover {
    cursor: pointer;
    border-bottom: 3px solid ${(props) => props.theme.brandPalette.primary};
  }

  &.active {
    font-weight: ${(props) => props.theme.fontWeights.bold};
    border-bottom: 3px solid ${(props) => props.theme.brandPalette.primary};
  }
`;

interface NavItemProps {
  title: string;
  activeTab: string;
  setActiveTab: (title: string) => void;
}

const TabNavItem = ({ title, activeTab, setActiveTab }: NavItemProps) => {
  const handleTabChange = () => {
    setActiveTab(title);
  };

  return (
    <NavItem
      onClick={handleTabChange}
      className={activeTab === title ? 'active' : ''}
    >
      {title}
    </NavItem>
  );
};
export default TabNavItem;
