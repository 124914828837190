import React from 'react';
import Insights from 'pageComponents/Insights';
import { graphql, HeadProps } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const InsightsPage = () => <Insights />;

export default InsightsPage;

export function Head(props: HeadProps) {
  const { t } = useTranslation();
  return (
    <title>{t('home.atf.navigation_tabs.insights')} | Safebrok</title>
  );
}
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
