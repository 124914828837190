import React from 'react';
import styled from 'styled-components';
import { breakpoints } from 'theme';

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 120px;
  margin-bottom: 80px;

  @media (max-width: ${breakpoints.tablet}) {
    margin-top: 40px;
    margin-bottom: 0;
    justify-content: flex-start;
  }
`;

interface TabContentProps {
  title: string;
  activeTab: string;
  children: React.ReactNode;
}

const TabContent = ({ title, activeTab, children }: TabContentProps) => {
  return activeTab === title ? <Container>{children}</Container> : null;
};

export default TabContent;
