import React from 'react';
import Box from 'components/Box';
import styled from 'styled-components';
import { rem } from 'polished';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { breakpoints } from 'theme';

const Container = styled.section`
  text-align: center;

  @media (max-width: ${breakpoints.tablet}) {
    text-align: left;
  }
`;

const Header = styled.h2`
  text-transform: uppercase;
  font-weight: ${(props) => props.theme.fontWeights.light};
  font-size: ${rem(20)};
  line-height: ${rem(32)};
  color: ${(props) => props.theme.neutralPalette.neutral6};

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${rem(18)};
    line-height: ${rem(22)};
  }
`;

const Subtitle = styled.h3`
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(32)};
  line-height: ${rem(46)};
  margin-top: 15px;
  color: ${(props) => props.theme.brandPalette.dark};

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${rem(28)};
    line-height: ${rem(32)};
  }
`;

const Text = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${rem(20)};
  line-height: ${rem(32)};
  color: ${(props) => props.theme.brandPalette.dark};
  opacity: 0.8;
  margin-top: 32px;

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${rem(16)};
    line-height: ${rem(24)};
    margin-top: 24px;
  }
`;

const About = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Container>
        <Header>{t('home.atf.navigation_tabs.insights')}</Header>
        <Subtitle>{t('blog.insights.title')}</Subtitle>
        <Text>{t('blog.insights.description')}</Text>
      </Container>
    </Box>
  );
};

export default About;
