import Box from 'components/Box';
import React, { useState } from 'react';
import styled from 'styled-components';

import BlogsTab from './Tabs/BlogsTab';
import PressTab from './Tabs/PressTab';
import TabContent from './components/TabContent';
import TabNavItem from './components/TabNavItem';
import { PostType } from 'utils/constants/PostType';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { breakpoints } from 'theme';

const Container = styled.section`
  text-align: center;
`;

const TabList = styled.ul`
  display: flex;
  justify-content: center;
  gap: 32px;
  list-style: none;

  @media (max-width: ${breakpoints.tablet}) {
    justify-content: flex-start;
  }
`;

interface TabsProps {
  content: any;
  isLoading: boolean;
  error: string | null;
}

const TabTitles = {
  BLOG: 'blog.insights.tabs.blog',
  PRESS: 'blog.insights.tabs.press',
};

const Tabs = ({ content, isLoading, error }: TabsProps) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<string>(t(TabTitles.BLOG));

  const pressPosts = content.filter(
    (item: any) => item.sys.contentType.sys.id === PostType.PRESS,
  );

  const blogPosts = content.filter(
    (item: any) => item.sys.contentType.sys.id === PostType.BLOG_PREVIEW,
  );

  const NAV_ITEMS = [
    {
      title: t(TabTitles.BLOG),
      content: (
        <BlogsTab posts={blogPosts} isLoading={isLoading} error={error} />
      ),
    },
    {
      title: t(TabTitles.PRESS),
      content: (
        <PressTab posts={pressPosts} isLoading={isLoading} error={error} />
      ),
    },
  ];

  return (
    <Box>
      <Container>
        <TabList>
          {NAV_ITEMS.map((item) => (
            <TabNavItem
              title={item.title}
              key={item.title}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          ))}
        </TabList>

        {NAV_ITEMS.map((item) => (
          <TabContent key={item.title} title={item.title} activeTab={activeTab}>
            {item.content}
          </TabContent>
        ))}
      </Container>
    </Box>
  );
};
export default Tabs;
