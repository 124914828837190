import React, { useEffect, useState } from 'react';
import { HeadFC, navigate } from 'gatsby';

import PageHero from 'components/PageHero';
import About from './components/About';
import Tabs from 'components/TabComponent';
import fetchPosts from 'services/fetchPosts';
import { useTranslation } from 'react-i18next';
import { filterByLanguageTag } from 'utils/constants/ContentfulLanguageTags';

const Insights = () => {
  const [allPosts, setAllPosts] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { i18n } = useTranslation();

  useEffect(() => {
    let isMounted = true;

    const fetchAllPosts = async () => {
      try {
        setIsLoading(true);
        setErrorMessage(null);

        const res = await fetchPosts({});

        if (!res) {
          throw new Error('Something went wrong, please try again.');
        }

        const filteredResponse = filterByLanguageTag({
          contentfulResponse: res,
          language: i18n.language,
        });

        setAllPosts(filteredResponse);
      } catch {
        setErrorMessage('Something went wrong, please try again.');
        navigate('/something-went-wrong');
      } finally {
        setIsLoading(false);
      }
    };

    if (isMounted) {
      fetchAllPosts();
    }

    return () => {
      isMounted = false;
    };
  }, [i18n.language]);

  return (
    <>
      <PageHero />
      <About />
      <Tabs content={allPosts} isLoading={isLoading} error={errorMessage} />
    </>
  );
};

export default Insights;

export const Head: HeadFC = () => <title>Insights</title>;
