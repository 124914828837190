import ListEmptyState from 'components/ListEmptyState';
import LoadingSpinner from 'components/LoadingSpinner';
import PressPostPreview from 'components/PressPostPreview';
import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { breakpoints } from 'theme';

import NoPressPosts from 'images/NoPressPosts.svg';

const ErrorMessage = styled.p`
  color: ${(props) => props.theme.semanticPalette.error.base};
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(16)};
  margin: 16px 0;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;

  @media (max-width: ${breakpoints.tablet}) {
    gap: 40px;
    margin-bottom: 40px;
  }
`;

interface PressTabProps {
  posts: any;
  isLoading: boolean;
  error: string | null;
}

const PressTab = ({ posts, isLoading, error }: PressTabProps) => {
  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error !== null) {
    return <ErrorMessage>{error}</ErrorMessage>;
  }

  if (!isLoading && posts.length === 0) {
    return (
      <ListEmptyState
        image={NoPressPosts}
        title={'activity_press_empty_title'}
        subtitle={'activity_press_empty_body'}
      />
    );
  }

  return (
    <div>
      <Flex>
        {posts.map((item: any) => (
          <PressPostPreview key={item.fields.title} element={item.fields} />
        ))}
      </Flex>
    </div>
  );
};
export default PressTab;
